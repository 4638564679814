.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}
.software-skill-inline > p {
  color: #868e96;
  font-size: 10px;
}
/* .software-skill-inline > i:hover ~ p{
  color: #645beb;
} */

.software-skill-inline:hover {
  transform: scale(0.95);
  transition: 0.4s;
}
.iconify {
  border-radius: 10px;
  padding: 10px;
}

.iconify:hover {
  transition: 0.4s;
  box-shadow: inset -2px -2px 8px rgb(220, 220, 220, 1),
    inset -2px -2px 12px rgb(220, 220, 220, 0.5),
    inset 2px 2px 4px rgb(220, 220, 220, 0.1),
    inset 2px 2px 8px rgb(0, 0, 0, 0.1);
}

.skills-text-subtitle {
  font-size: 56px;
  font-weight: 400;
}

@media (max-width: 1380px) {
  .skills-text-subtitle {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .skills-text-subtitle {
    font-size: 30px;
    text-align: center;
  }
}
